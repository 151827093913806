import RightActionArea from "../../../components/ActionAreaRight";

import { Icon } from "@iconify/react";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import { PricingInput, TextInput } from "../components/DataEntry";
import InputWithDropdown from "../../../components/InputWithDropdown";
import { useEffect, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Input from "../../../components/Input";
import InputForVolumePricing from "../../../components/InputWithGraduatedAndVolume";
import DialogBox from "../../../components/Dialog";
import { PRICING_TYPES } from "../../../constants/constants";
import { rsort } from "semver";
import { Paragraph } from "../../../components/Typography/Paragraph";
import TextEditor from "../../../components/TextEditor";
import Tip from "../../../components/Tip";
import { StopCircleIcon } from "@heroicons/react/24/solid";
import Divider from "../../../components/Divider";

const base = false;
export default function PricingResponseForm(props) {
  const {
    setServiceDrawerOpen,
    serviceDrawerOpen,
    autosaving,
    setDrawerOpen,
    drawerOpen,
    fullQuote,
    pricingOptions,
    unitOptions,
    selectedTab,
    response,
    handleFieldChange,
    handleUpdateAfterDeleteLineItem,
    handlePricingChange,
    handleselect,
    handleSaveServiceClick,
    isLoading,
    isSaved,
    err,
    setErr,
    errMessage,
    setErrMessage,
    field,
    hideAddLineItems,
  } = props;

  const [pricing, setPricing] = useState();
  const [selected, setSelected] = useState([]);
  const [tiers, setTiers] = useState([
    {
      min: 1,
      max: "∞",
      price: 0.01,
      currency: "USD",
      flat: {
        price: 0.0, //document, page, hour, GB
        total: 0.0,
      },
      unit_pricing: {
        price: 0.0, //document, page, hour, GB
        total: 0.0,
      },

      unit: "document", //document, page, hour, GB
    },
  ]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dataToConfirm, setDataToConfirm] = useState();
  const [dataMiningLineItems, setDataMiningLineItems] = useState([]);
  const [estimatedPrice, setEstimatedPrice] = useState("");

  useEffect(() => {
    if (response && response[selectedTab] && response[selectedTab]["price"]) {
      setEstimatedPrice(response[selectedTab]["price"]);
    }
  }, [response, selectedTab]);

  useEffect(() => {
    // if Object.keys(pricing) are in pricingOptions, remove them from pricingOptions and set the new pricingOptions
    let newPricingOptions = [...pricingOptions];
    if (pricing && pricing !== undefined && pricing !== null) {
      Object.keys(pricing).map((key, index) => {
        let idx = newPricingOptions.findIndex(
          (x) => x.name === key || x.value === key
        );
        if (idx > -1) {
          newPricingOptions.splice(idx, 1);
        }
      });
    } else {
      return;
    }

    setDataMiningLineItems(newPricingOptions);
  }, [selectedTab, pricingOptions]);

  useEffect(() => {
    // console.log(
    //   "USEEFFECT:PricingResponseForm",
    //   selectedTab,
    //   field,
    //   response[selectedTab][field]
    // );
    response &&
    response !== undefined &&
    response !== null &&
    response[selectedTab] &&
    response[selectedTab][field] &&
    response[selectedTab][field] !== undefined &&
    field == "pricing"
      ? setPricing(response[selectedTab][field])
      : setPricing({});
  }, [selectedTab, field]);

  useEffect(() => {
    console.log("USEEFFECT:PricingResponseForm", response && response);
  }, [response]);

  const handleSetEstimatedPrice = (e, value) => {
    console.log("handleSetEstimatedPrice", value);
    if (isNaN(value)) {
      console.log("handleSetEstimatedPrice isNaN failed");
      setErr(true);
      setErrMessage("You may only enter numbers for the estimated price.");
      setTimeout(() => {
        setErr(false);
        setErrMessage("");
      }, 5000);
    } else {
      console.log("handleSetEstimatedPrice passed checks");
      setErr(false);
      setErrMessage("");
      setEstimatedPrice(value);
      handleFieldChange(e, selectedTab, "handleSetEstimatedPrice");
    }
  };

  const handlePriceChange = (value, service, item, line_item_type) => {
    // value is the value of the input
    // service is the name of the service
    // item is the name of the pricing item
    // line_item_type is the type of line item, standard, graduated, volume

    item = item ? item : "base";

    let newPricing = { ...pricing };

    //pricing item does not exist, create it
    if (!newPricing[item]) {
      newPricing[item] = {
        type: line_item_type,
        price: value.price,
        currency: "USD",
        measure: "per_unit", //flat, per_unit
        unit: value.unit, //document, page, hour, GB
        tiers: value.tiers,
      };
      console.log("handlePriceChange", value, service, item, line_item_type);
    } else {
      //pricing item exists, update it without knowing all the incoming values
      Object.keys(value).map((key, index) => {
        newPricing[item][key] = value[key];
      });
    }
    let e = {
      target: {
        value: newPricing,
        name: "pricing",
      },
    };
    setPricing(newPricing);
    handleFieldChange(e, selectedTab);
  };
  const addLineItem = (e, service, type) => {
    let newPricing = { ...pricing };
    let pricingSize = Object.keys(newPricing).length;
    let tmpKey = "tmp" + pricingSize;
    newPricing[tmpKey] = {
      type: e,
    };
    console.log("addLineItem", e, service, field, type);
    setPricing(newPricing);
  };
  const handleSetLineItem = (e, service, field, type, item) => {
    //rename tmp to the name of the line item
    let newPricing = { ...pricing };
    let keys = Object.keys(newPricing);
    console.log("Compare", e, keys, " for duplicates");
    //check to see if the new line (e) already exists in the pricing object
    let exists = keys.includes(e);
    //if it exists, set an error and alert
    if (exists) {
      console.log("exists", exists);
      // window.alert("This line item already exists");
      // err(true);
      // errMessage("This line item already exists");
    } else {
      console.log("does not exist", exists);
      // window.alert("This line item does not exist", e);
      let tmp = newPricing[item ? item : "tmp"];
      delete newPricing[item];
      newPricing[e] = tmp;
      console.log("handleSetLineItem", e, service, field, type);
      setPricing(newPricing);
      //remove e from the lineitem options
      let newLineItems = [...dataMiningLineItems];
      let index = newLineItems.findIndex((x) => x.name === e);
      newLineItems.splice(index, 1);
      setDataMiningLineItems(newLineItems);
    }
  };

  const deleteLineItem = (e, item) => {
    e.preventDefault();
    console.log("deleteLineItem", e, item);
    // window.alert("Delete line item", e, item);
    //show confirmation box first
    //if confirmed, delete line item
    //if not confirmed, do nothing
    setConfirmDialogOpen(true);
    setDataToConfirm(item);
  };
  const handleConfirmDelete = (e) => {
    console.log(
      "Deleting item, deleteLineItem -> handleConfirmDelete",
      dataToConfirm
    );
    let newPricing = { ...pricing };
    delete newPricing[dataToConfirm];
    console.log(
      "deleteLineItem -> handleConfirmDelete -> newPricing",

      dataToConfirm,
      newPricing
    );
    setPricing(newPricing);
    setDataToConfirm();
    setConfirmDialogOpen(false);
    //update response state
    handleUpdateAfterDeleteLineItem(dataToConfirm, newPricing);
    //update line item options to include the deleted item
    let newLineItems = [...dataMiningLineItems];
    newLineItems.push({ name: dataToConfirm, value: dataToConfirm });
    setDataMiningLineItems(newLineItems);
  };

  return (
    <div className="h-full overflow-hidden ">
      <div className="sticky top-0 bg-white space-y-2 mb-0 ">
        <div className="w-full flex items-center justify-between min-h-8 max-h-8">
          <div className="flex items-center ">
            <h2 className="text-lg font-semibold leading-6 font-medium text-gray-900">
              {utils.cleanUpKeys(selectedTab)} Response
            </h2>
          </div>
          <div className="flex items-center space-x-6">
            {autosaving ? (
              <span className="inline-flex items-center text-kelly-500 font-medium mr-2">
                <Icon
                  icon="ic:baseline-check-circle"
                  className="mr-1 w-5 h-5 text-green-500"
                />
                Autosaving...
              </span>
            ) : (
              <Button
                size={"sm"}
                loader={isLoading(selectedTab) && !autosaving}
                // loader={false}
                disabled={
                  fullQuote.status == "submitted" || err || isSaved(selectedTab)
                }
                handleClick={(e) => {
                  handleSaveServiceClick(e, selectedTab);
                }}
                type="submit"
                color="secondary"
                className=""
                // disabled={err || saved}
                // className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <span className="flex items-center">
                  <Icon icon="mdi:content-save" className="flex mr-2" />
                  <span className="hidden lg:flex">
                    Save{" "}
                    {utils.cleanUpKeys(
                      selectedTab && selectedTab !== undefined
                        ? selectedTab
                        : "Service"
                    )}
                  </span>
                </span>
              </Button>
            )}

            <Button
              color={"emerald"}
              size={"sm"}
              handleClick={() => setServiceDrawerOpen(true)}
            >
              <Icon icon="gravity-ui:square-list-ul" className="mr-2" />
              <span className="hidden lg:flex">
                Show {utils.cleanUpKeys(selectedTab)} Request
              </span>
            </Button>
            <Button
              size={"sm"}
              color={"outline"}
              handleClick={(e) => {
                e.preventDefault();
                console.log("Pricing help clicked");
                setDrawerOpen(true);
              }}
              className="mr-2"
              variant="secondary"
              loader={isLoading("pricing-help")}
            >
              <Icon icon="mdi:help-circle-outline" className="mr-2" />{" "}
              <span className="hidden lg:flex">Pricing help</span>
            </Button>
          </div>
        </div>

        <div className="col-span-full mt-0">
          {/* <Paragraph md>Enter your response for this service below.</Paragraph> */}
        </div>
      </div>
      <div
        className={`h-full pb-48 col-span-12  px-3 overflow-y-auto overflow-x-hidden 
    scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
    scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
      >
        {" "}
        <Tip
          icon={
            <Icon
              icon="mingcute:alert-fill"
              className=" my-auto inline-flex w-5 h-5 mr-1 text-red-500"
            />
          }
          color="red"
          colSpan={"full"}
        >
          <span className="">
            {" "}
            <span className="font-semibold">
              {" "}
              Please read the bid request and its assumptions carefully.{" "}
            </span>
            The requestor requires your response and all comments to be{" "}
            <span className="font-semibold">
              based on the bid request and its assumptions
            </span>
            . Additionally,{" "}
            <span className="font-semibold">
              you must enter line items for all services
            </span>{" "}
            you bill for. You may use the Additional Comments field for line
            items that are not listed. When entering the Estimated Cost,{" "}
            <span className="font-semibold">
              enter the Estimated Total Cost of the bid
            </span>
            . For example, do not enter $1 if your price is $1 per document or
            letter or redemption. If you have any questions, please ask the
            requestor in the comments.{" "}
            <span className="font-bold">
              Failure to follow the bid request instructions may result in your
              bid being disqualified or sent back for revision.
            </span>
          </span>
        </Tip>
        <div
          id={"responsePanel"}
          className="bg flex flex-col col-span-full items-center space-y-4  pb-12 m-auto "
        >
          <div className=" w-full">
            <div
              className="flex bg-brand-50 border-2 border-dashed border-brand-200 px-6 pb-2 rounded-sm"
              // className={"-mt-12"}
              noIcon
            >
              <div className="relative w-full col-span-full py-1">
                <label
                  htmlFor="service_price"
                  className="text-sm font-medium  mb-2"
                >
                  <span className="py-4">
                    Estimated Cost for{" "}
                    {utils.cleanUpKeys(
                      selectedTab && selectedTab !== undefined
                        ? selectedTab
                        : "Service"
                    )}
                  </span>
                </label>
                <div className="pb-3  items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
                  $
                </div>
                <input
                  id="price"
                  name="price"
                  type="text"
                  autoComplete="price"
                  disabled={fullQuote && fullQuote.status == "submitted"}
                  required
                  placeholder="Estimated total cost for this service."
                  className="mt-2 block w-full min-w-full max-w-full pl-10 pr-40 py-3 placeholder-gray-500  focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                  //   placeholder={`Enter your ${utils.cleanUpKeys(field)} pricing.`}
                  value={estimatedPrice}
                  onChange={(e) => {
                    handleSetEstimatedPrice(e, e.target.value);
                  }}
                />
              </div>
              {err && (
                <label
                  className="w-full mt-1 space-y-0 text-sm text-red-600"
                  id="email-error"
                >
                  {errMessage}
                </label>
              )}
            </div>
          </div>
          {base && (
            <InputWithDropdown
              version={3}
              fullQuote={fullQuote}
              selectedTab={selectedTab}
              label={"Base Pricing"}
              lineItem={"base"}
              unitOptions={unitOptions}
              service={selectedTab}
              response={response}
              pricing={pricing}
              field={field}
              handlePriceChange={(v, service) =>
                handlePriceChange(v, service, "base", "Standard")
              }
              indicator="$"
              value={pricing && pricing["base"] && pricing["base"]}
              isLoading={isLoading}
              isSaved={isSaved}
              handleSaveServiceClick={handleSaveServiceClick}
              err={err}
              errMessage={errMessage}
              //not used?
              handleFieldChange={handleFieldChange}
              onFieldChange={handleFieldChange}
              // handleSelect={handleSelect}
            />
          )}
          {pricing &&
            !hideAddLineItems &&
            Object.keys(pricing).map((item, index) => {
              console.log("MAPPING PRICING", item, pricing[item].type);
              if (item !== "base" && pricing[item].type == "Standard") {
                return (
                  <InputWithDropdown
                    version={2}
                    fullQuote={fullQuote}
                    selectedTab={selectedTab}
                    service={selectedTab}
                    response={response}
                    // label={utils.cleanUpKeys(item)}
                    pricing={pricing}
                    field={field}
                    lineItem={item}
                    lineItemOptions={dataMiningLineItems}
                    unitOptions={unitOptions}
                    deleteLineItem={(e) => deleteLineItem(e, item)}
                    handlePriceChange={(v, service, item) =>
                      handlePriceChange(v, service, item, "Standard")
                    }
                    handleSelectLineItem={(v, service, field, type) =>
                      handleSetLineItem(v, service, field, type, item)
                    }
                    indicator="$"
                    value={pricing && pricing[item] && pricing[item]}
                    isLoading={isLoading}
                    isSaved={isSaved}
                    handleSaveServiceClick={handleSaveServiceClick}
                    err={err}
                    errMessage={errMessage}
                    //not used?
                    handleFieldChange={handleFieldChange}
                    onFieldChange={handleFieldChange}
                    // handleSelect={handleSelect}
                  />
                );
              } else if (
                pricing[item].type == "Graduated" ||
                pricing[item].type == "Volume"
              )
                return (
                  <InputForVolumePricing
                    version={2}
                    fullQuote={fullQuote}
                    selectedTab={selectedTab}
                    service={selectedTab}
                    response={response}
                    label={utils.cleanUpKeys(item)}
                    pricing={pricing}
                    field={field}
                    item={item}
                    lineItem={item}
                    deleteLineItem={(e) => deleteLineItem(e, item)}
                    lineItemOptions={dataMiningLineItems}
                    unitOptions={() => {
                      //remove fixed price from the unit options
                      let newUnitOptions = [...unitOptions];
                      let index = newUnitOptions.findIndex(
                        (x) => x.name === "fixed"
                      );
                      newUnitOptions.splice(index, 1);
                      return newUnitOptions;
                    }}
                    handlePriceChange={(v, service) =>
                      handlePriceChange(v, service, item, pricing[item].type)
                    }
                    handleSelectLineItem={(v, service, field, type) =>
                      handleSetLineItem(v, service, field, type, item)
                    }
                    indicator="$"
                    value={pricing && pricing[item] && pricing[item]}
                    isLoading={isLoading}
                    isSaved={isSaved}
                    handleSaveServiceClick={handleSaveServiceClick}
                    err={err}
                    errMessage={errMessage}
                    //not used?
                    handleFieldChange={handleFieldChange}
                    onFieldChange={handleFieldChange}
                    // handleSelect={handleSelect}
                  />
                  // <div className="w-full">
                  //   <div className="flex items-center justify-between">
                  //     <label
                  //       htmlFor={field}
                  //       className="block text-sm font-medium text-gray-700"
                  //     >
                  //       {" "}
                  //       {item !== "tmp" ? (
                  //         <span className="block text-sm font-medium text-gray-700">
                  //           {item}
                  //         </span>
                  //       ) : (
                  //         <span className="block text-sm font-medium text-gray-700">
                  //           <ComboboxSelector
                  //             name={"unit"}
                  //             options={[
                  //               { name: "PDF", value: "pdf" },
                  //               { name: "Processing", value: "processing" },
                  //               {
                  //                 name: "Project Management",
                  //                 value: "project_management",
                  //               },
                  //             ]}
                  //             textbutton={true}
                  //             buttonType={"pricing_item"}
                  //             fieldType={"single"}
                  //             open={true}
                  //             //   handleSelect={(v) => handleSelect(v, service, "unit")}
                  //             //   handleSelect={(v) => {
                  //             //     return handleChangeInputDropdownValue("line_item", v);
                  //             //   }}
                  //             handleSelect={(v, service, field, type) =>
                  //               handleSetLineItem(v, service, field, type)
                  //             }
                  //             // handleDeselect={handleDeselect}
                  //             handleClearAllSelections={(value) =>
                  //               console.log("handleClearAllSelections", value)
                  //             } //{handleClearAllSelections}
                  //             // value={opts2[field] ? opts2[field] : null}
                  //             value={item}
                  //           />
                  //         </span>
                  //       )}
                  //     </label>

                  //     <Button
                  //       color="text"
                  //       handleClick={() => insertNewTier(item)}
                  //     >
                  //       Insert Tier
                  //     </Button>
                  //   </div>
                  //   <table class="w-full text-sm text-left text-gray-500 ">
                  //     <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                  //       <tr>
                  //         <th scope="col" class="px-6 py-3">
                  //           {" "}
                  //           {pricing[item].type == "Graduated"
                  //             ? "Graduated "
                  //             : "Volume "}
                  //         </th>
                  //         <th scope="col" class="px-6 py-3">
                  //           Start
                  //         </th>
                  //         <th scope="col" class="px-6 py-3">
                  //           End
                  //         </th>
                  //         <th scope="col" class="px-6 py-3">
                  //           Per Unit
                  //         </th>
                  //         <th scope="col" class="px-6 py-3">
                  //           Flat Fee
                  //         </th>
                  //       </tr>
                  //     </thead>
                  //     <tbody>
                  //       {/* <tr class="bg-white border-b  dark:border-gray-700">
                  //       <th
                  //         scope="row"
                  //         class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  //       >
                  //         1-5000
                  //       </th>
                  //       <td class="px-6 py-4">1</td>
                  //       <td class="px-6 py-4">5000</td>
                  //       <td class="px-6 py-4">$0.00</td>
                  //       <td class="px-6 py-4">$0.00</td>
                  //     </tr> */}
                  //       {tiers &&
                  //         tiers.map((tier, idx) => {
                  //           return (
                  //             <tr class="bg-white border-b  dark:border-gray-700">
                  //               <th
                  //                 scope="row"
                  //                 class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  //               >
                  //                 {tier.min}-{tier.max ? tier.max : "∞"}
                  //               </th>
                  //               <td class="px-6 py-4">{tier.min}</td>
                  //               <td class="px-6 py-4">
                  //                 <Input
                  //                   value={
                  //                     idx == tiers.length - 1
                  //                       ? "∞"
                  //                       : tier.max == "∞" ||
                  //                         tier.max == null ||
                  //                         tier.max == undefined ||
                  //                         tier.max == NaN ||
                  //                         tier.max == "NaN" ||
                  //                         isNaN(tier.max)
                  //                       ? ""
                  //                       : tier.max
                  //                   }
                  //                   disabled={idx == tiers.length - 1}
                  //                   onChange={(e) =>
                  //                     handleTierValueChange(
                  //                       e.target.value,
                  //                       idx,
                  //                       "max"
                  //                     )
                  //                   }
                  //                 />
                  //               </td>
                  //               <td class="px-6 py-4">
                  //                 <Input
                  //                   value={tier.unit_pricing.price}
                  //                   indicator="$"
                  //                   disabled={tier.flat.price > 0}
                  //                   onChange={(e) =>
                  //                     handleTierValueChange(
                  //                       e.target.value,
                  //                       idx,
                  //                       "unit_pricing.price"
                  //                     )
                  //                   }
                  //                 />
                  //               </td>
                  //               <td class="px-6 py-4 relative">
                  //                 <Input
                  //                   indicator="$"
                  //                   value={tier.flat.price}
                  //                   disabled={tier.unit_pricing.price > 0}
                  //                   onChange={(e) =>
                  //                     handleTierValueChange(
                  //                       e.target.value,
                  //                       idx,
                  //                       "flat.price"
                  //                     )
                  //                   }
                  //                 />
                  //                 <span className="bottom-5 absolute -right-4">
                  //                   <Button
                  //                     iconButton
                  //                     color="text"
                  //                     handleClick={() => deleteTier(idx)}
                  //                   >
                  //                     <XMarkIcon className="w-5 h-5" />
                  //                   </Button>
                  //                 </span>
                  //               </td>
                  //             </tr>
                  //           );
                  //         })}
                  //       {/* <tr class="bg-white border-b  dark:border-gray-700">
                  //       <th
                  //         scope="row"
                  //         class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  //       >
                  //         5001-
                  //       </th>
                  //       <td class="px-6 py-4">5001</td>
                  //       <td class="px-6 py-4"></td>
                  //       <td class="px-6 py-4">$0.00</td>
                  //       <td class="px-6 py-4">$0.00</td>
                  //     </tr> */}
                  //     </tbody>
                  //   </table>
                  // </div>
                );
            })}
          {err && (
            <label
              className="w-full mt-1 space-y-0 text-sm text-red-600"
              id="email-error"
            >
              {errMessage}
            </label>
          )}{" "}
          <div className="w-full relative"></div>
          {!hideAddLineItems && (
            <div className="w-full col-span-full ">
              <ComboboxSelector
                name={"unit"}
                options={PRICING_TYPES}
                textbutton={true}
                buttonType={"line_item_divider"}
                fieldType={"single"}
                open={true}
                handleSelect={(v) => {
                  addLineItem(v, selectedTab, "line_item");
                }}
                handleDeselect={null}
                handleClearAllSelections={(value) => null} //{handleClearAllSelections}
                value={() => {
                  "project_management";
                }}
              />
            </div>
          )}
          <div className="w-full col-span-full ">
            <label
              htmlFor="additional_comments"
              className="text-sm font-medium pb-6"
            >
              Additional Information for{" "}
              {utils.cleanUpKeys(
                selectedTab && selectedTab !== undefined
                  ? selectedTab
                  : "Service"
              )}
            </label>
            <TextEditor
              field="additional_comments_object"
              selectedTab={selectedTab}
              response={response}
              handleFieldChange={(e, rawContent) => {
                handleFieldChange(
                  {
                    target: {
                      name: "additional_comments_object",
                      value: rawContent,
                    },
                  },
                  selectedTab
                );
              }}
              handleSaveToAdditionalComments={(e, content) => {
                handleFieldChange(
                  {
                    target: {
                      name: "additional_comments",
                      value: content,
                    },
                  },
                  selectedTab
                );
              }}
              handleSaveServiceClick={handleSaveServiceClick}
              isLoading={isLoading}
              isSaved={isSaved}
              err={err}
              errMessage={errMessage}
              value={
                response &&
                response[selectedTab] &&
                response[selectedTab]["additional_comments_object"]
                  ? response[selectedTab]["additional_comments_object"]
                  : ""
              }
            />
            {/* <textarea
            //multi line

            id="additional_comments"
            name="additional_comments"
            type="text"
            autoComplete="additional_comments"
            rows={8}
            disabled={fullQuote && fullQuote.status == "submitted"}
            value={
              response &&
              response[selectedTab] &&
              response[selectedTab]["additional_comments"]
                ? response[selectedTab]["additional_comments"]
                : ""
            }
            // value={response[selectedTab]["additional_comments"] || ""} //{selectedTab}
            className="max-w-full min-w-full w-full  px-5 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
            placeholder="Enter your quote for this service here"
            onChange={(e) => {
              handleFieldChange(e, selectedTab);
            }}
          /> */}
          </div>
          <div className="mt-6 w-2/3 rounded-md  sm:mt-3 sm:ml-3 sm:flex-shrink-0">
            {/* <Button
              loader={isLoading(selectedTab)}
              disabled={
                fullQuote.status == "submitted" || err || isSaved(selectedTab)
              }
              handleClick={(e) => {
                handleSaveServiceClick(e, selectedTab);
              }}
              type="submit"
              color="secondary"
              fullWidth
              className="w-full"
              // disabled={err || saved}
              // className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              {autosaving ? (
                <span className="inline-flex items-center text-kelly-500 font-medium mr-2">
                  <Icon
                    icon="ic:baseline-check-circle"
                    className="mr-1 w-5 h-5 text-green-500"
                  />
                  Autosaving...
                </span>
              ) : (
                <span>
                  <Icon icon="mdi:content-save" className="mr-2" />

                  {utils.cleanUpKeys(
                    selectedTab && selectedTab !== undefined
                      ? selectedTab
                      : "Service"
                  )}
                </span>
              )}
            </Button> */}
          </div>
        </div>
        {confirmDialogOpen && (
          <DialogBox
            loading={false}
            open={confirmDialogOpen}
            // success={success}
            title="Delete Line Item"
            message={"Are you sure you want to delete this line item?"}
            OKbuttonText={"Delete"}
            CancelButton={"Cancel"}
            setOpen={setConfirmDialogOpen}
            data={dataToConfirm}
            handleConfirmClick={(data) => handleConfirmDelete(data)}
            handleCancelClick={() => setConfirmDialogOpen(false)}
          ></DialogBox>
        )}
      </div>
    </div>
  );
}
