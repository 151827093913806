import { utils } from "../modules/_utils";

let all_assumptions = {
  /*
    Field names for user fields must match a field in the solicitation.services.opts2 object.
    The field name is used to look up the value in the solicitation.services.opts2 object.
    Field names for system fields do not have to match a field in the solicitation.services.opts2 object.
    System fields are used for us to provide our own assumptions if the user does not provide a value.
    Warning fields are used to display a warning message if the user provides a specific value.
    The global section is not currently used.
*/

  global: {
    require_total: true,
    require_pricing_model: true,
    allow_additional_info: true,
    allow_attachments: true,
    allow_comments: true,
    allow_multiple_responses: true,
  },
  services: {
    mailings: {
      fields: [
        { name: "number_of_letters", title: "Letters to Mail", type: "user" },
        // {
        //   name: "mailing_jurisdictions",
        //   title: "Mailings Jurisdictions",
        //   type: "text",
        // },
        { name: "forms_of_letter", title: "Forms of Letter", type: "user" },
        {
          name: "double_sided_pages",
          title: "Double Sided Pages",
          type: "user",
        },
        {
          name: "return_mail",
          title: "Return Mail",
          type: "user",
          data_type: "boolean",
        },
        {
          name: "ncoa",
          title: "NCOA",
          type: "user",
          data_type: "boolean",
        },
        {
          name: "text_color",
          title: "Print Color",
          type: "user",
        },
        // {
        //   name: "data_compression",
        //   title: "Data Compression",
        //   label: "Value",
        //   type: "user",
        // },
        // {
        //   name: "expansion_rate",
        //   title: "Data Expansion Rate",
        //   label: "x",
        //   type: "user",
        // },
        // {
        //   name: "docs_per_gb",
        //   title: "Documents per GB",
        //   label: "docs/GB",
        //   type: "system",
        //   value: 5000,
        // },
        // {
        //   name: "document_count",
        //   title: "Number of Documents",
        //   label: "Documents",
        //   type: "user",
        // },

        // {
        //   name: "hours_per_week_for_status_calls",
        //   title: "Project Management",
        //   label: "Hours/wk",
        //   type: "user",
        // },
        // {
        //   name: "project_duration",
        //   title: "Project Duration",
        //   label: "months",
        //   type: "system",
        //   value: 1,
        // },
      ],
    },
    data_mining: {
      fields: [
        {
          name: "require_total",
          title: "Total",
          label: "Value",
          type: "warning",
          message: () =>
            `Please provide an estimated total price for this service.`,
        },
        {
          name: "require_pricing_model",
          title: "Pricing Model",
          label: "Value",
          type: "warning",
          message: () =>
            `The requestor has asked that you provide a pricing model to make it easier to compare quotes.`,
        },
        {
          name: "multiple_quotes",
          title: "Allow mixed quotes",
          label: "Value",
          type: "warning",
          message: (e, service) => {
            return `The requestor has asked that you only provide your ${utils.cleanUpKeys(
              service
            )} pricing on this tab. Please do not mix Data Mining and Review services.`;
          },
        },
        {
          name: "contingent_discounts",
          title: "Discounts",
          label: "Value",
          type: "warning",
          message: (e, service) => {
            return `If any discounts are contingent upon winning both Data Mining and Review, please provide those assumptions as part of the Review quote.`;
          },
        },
        {
          name: "offshore_resources",
          title: "Staffing Preference",
          label: "Value",
          type: "warning",
          message: (value) => {
            if (value === "onshore") {
              return `The requestor prefers a single quote for onshore resources.`;
            } else if (value === "offshore") {
              return `The requestor prefers a single quote for offshore resources.`;
            } else {
              return `The requestor doesn't have a preference between onshore and offshore resources. Please provide a single quote for 
                the most cost effective solution.`;
            }
          },
        },
        { name: "data_size", title: "Data Volume", label: "GB", type: "user" },
        {
          name: "data_compression",
          title: "Data Compression",
          label: "Value",
          type: "user",
        },
        {
          name: "expansion_rate",
          title: "Data Expansion Rate",
          label: "x",
          type: "user",
        },
        {
          name: "docs_per_gb",
          title: "Documents per GB",
          label: "docs/GB",
          type: "system",
          value: 5000,
        },
        {
          name: "document_count",
          title: "Number of Documents",
          label: "Documents",
          type: "user",
        },

        {
          name: "hours_per_week_for_status_calls",
          title: "Project Management",
          label: "Hours/wk",
          type: "user",
        },
        // {
        //   name: "project_duration",
        //   title: "Project Duration",
        //   label: "months",
        //   type: "system",
        //   value: 1,
        // },
      ],
    },
    document_review: {
      fields: [
        {
          name: "processed_before",
          title: "Has been processed",
          label: "Value",
          type: "user",
          data_type: "boolean",
        },
        {
          name: "require_total",
          title: "Total",
          label: "Value",
          type: "warning",
          message: () =>
            `Please provide an estimated total price for this service.`,
        },
        {
          name: "require_pricing model",
          title: "Pricing Model",
          label: "Value",
          type: "warning",
          message: () =>
            `The requestor has asked that you provide a pricing model to make it easier to compare quotes.`,
        },
        {
          name: "multiple_quotes",
          title: "Allow mixed quotes",
          label: "Value",
          type: "warning",
          message: (e, service) => {
            return `The requestor has asked that you only provide your ${utils.cleanUpKeys(
              service
            )} pricing on this tab. Please do not mix Data Mining and Review services.`;
          },
        },
        {
          name: "contingent_discounts",
          title: "Discounts",
          label: "Value",
          type: "warning",
          message: () => {
            return `The requestor asked that you provide any contingent discount assumptions in the Additional Information section, NOT in the Estimated Cost or Pricing Model.`;
          },
        },
        {
          name: "offshore_resources",
          title: "Staffing Preference",
          label: "Value",
          type: "warning",
          message: (value) => {
            if (value === "onshore") {
              return `The requestor prefers a single quote for onshore resources.`;
            } else if (value === "offshore") {
              return `The requestor prefers a single quote for offshore resources.`;
            } else {
              return `The requestor doesn't have a preference between onshore and offshore resources. Please provide a single quote for 
                  the most cost effective solution.`;
            }
          },
        },

        { name: "data_size", title: "Data Volume", label: "GB", type: "user" },
        {
          name: "data_compression",
          title: "Data Compression",
          label: "Value",
          type: "user",
        },
        {
          name: "expansion_rate",
          title: "Data Expansion Rate",
          label: "x",
          type: "user",
        },
        {
          name: "document_count",
          title: "Number of Documents",
          label: "Documents",
          type: "user",
        },
        {
          name: "survival_rate",
          title: "Documents Requiring Manual Review",
          label: "%",
          type: "user",
        },
        {
          name: "hosting_option",
          title: "Hosting Option",
          label: "Value",
          type: "user",
        },

        {
          name: "offshore_resources",
          title: "Staffing Preference",
          label: "Value",
          type: "warning",
          message: (value) => {
            if (value === "onshore") {
              return `The requestor prefers using onshore resources for this project. Please do not quote offshore staffing.`;
            } else if (value === "offshore") {
              return `The requestor prefers using offshore resources for this project. Please do not quote onshore staffing.`;
            } else {
              return `The requestor doesn't prefer onshore or offshore resources. Please quote the most cost effective solution.`;
            }
          },
        },
        {
          name: "hours_per_week_for_status_calls",

          title: "Project Management",
          label: "hrs/wk",
          type: "user",
        },
        {
          name: "project_duration",
          title: "Project Duration",
          label: "weeks",
          type: "user",
        },
      ],
    },
    ediscovery_document_review: {
      fields: [
        {
          name: "require_total",
          title: "Total",
          label: "Value",
          type: "warning",
          message: () =>
            `Please provide an estimated total price for this service.`,
        },
        {
          name: "require_pricing model",
          title: "Pricing Model",
          label: "Value",
          type: "warning",
          message: () =>
            `The requestor has asked that you provide a pricing model to make it easier to compare quotes.`,
        },
        // {
        //   name: "multiple_quotes",
        //   title: "Allow mixed quotes",
        //   label: "Value",
        //   type: "warning",
        //   message: (e, service) => {
        //     return `The requestor has asked that you only provide your ${utils.cleanUpKeys(
        //       service
        //     )} pricing on this tab. Please do not mix Data Mining and Review services.`;
        //   },
        // },
        {
          name: "contingent_discounts",
          title: "Discounts",
          label: "Value",
          type: "warning",
          message: () => {
            return `The requestor asked that you provide any contingent discount assumptions in the Additional Information section, NOT in the Estimated Cost or Pricing Model.`;
          },
        },
        // {
        //   name: "offshore_resources",
        //   title: "Staffing Preference",
        //   label: "Value",
        //   type: "warning",
        //   message: (value) => {
        //     if (value === "onshore") {
        //       return `The requestor prefers a single quote for onshore resources.`;
        //     } else if (value === "offshore") {
        //       return `The requestor prefers a single quote for offshore resources.`;
        //     } else {
        //       return `The requestor doesn't have a preference between onshore and offshore resources. Please provide a single quote for
        //           the most cost effective solution.`;
        //     }
        //   },
        // },
        {
          name: "offshore_resources",
          title: "Staffing Preference",
          label: "Value",
          type: "warning",
          message: (value) => {
            if (value === "onshore") {
              return `The requestor prefers using onshore resources for this project. Please do not quote offshore staffing.`;
            } else if (value === "offshore") {
              return `The requestor prefers using offshore resources for this project. Please do not quote onshore staffing.`;
            } else {
              return `The requestor doesn't prefer onshore or offshore resources. Please quote the most cost effective solution.`;
            }
          },
        },

        { name: "data_size", title: "Data Volume", label: "GB", type: "user" },
        {
          name: "data_compression",
          title: "Data Compression",
          label: "Value",
          type: "user",
        },
        {
          name: "expansion_rate",
          title: "Data Expansion Rate",
          label: "x",
          type: "user",
        },
        {
          name: "document_count",
          title: "Number of Documents",
          label: "Documents",
          type: "user",
        },
        {
          name: "survival_rate",
          title: "Survival Rate",
          label: "%",
          type: "user",
        },
        {
          name: "hosting_option",
          title: "Hosting Option",
          label: "Value",
          type: "user",
        },

        {
          name: "hours_per_week_for_status_calls",

          title: "Project Management",
          label: "hrs/wk",
          type: "user",
        },
        {
          name: "project_duration",
          title: "Project Duration",
          label: "weeks",
          type: "user",
        },
        {
          name: "preferred_tools",
          title: "Preferred Tools",
          label: "Value",
          type: "text",
        },
        {
          name: "key_assumptions",
          title: "Key Assumptions",
          label: "Value",
          type: "text",
          message: () =>
            `Please provide any key assumptions that you used to calculate your quote.`,
        },
      ],
    },
  },
};
export default all_assumptions;
